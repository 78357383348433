var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "财务报表",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "main"
  }, [_vm.finances ? _c('div', {
    staticClass: "data-list"
  }, _vm._l(_vm.finances, function (finance, index) {
    return _c('van-cell-group', {
      key: index,
      attrs: {
        "title": finance.store.name
      }
    }, [_c('van-cell', {
      attrs: {
        "value-class": "cell-value",
        "title": "仓库商品总额",
        "value": "\uFFE5".concat(finance.total_amount),
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(finance, 0);
        }
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "cell-value",
        "title": "可售商品总额",
        "value": "\uFFE5".concat(finance.allow_sales_amount),
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(finance, 1);
        }
      }
    })], 1);
  }), 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }