<template>
  <div>
    <my-nav-bar
      title="财务报表"
      left-text="返回"
      left-arrow
    />
    <div class="main">
      <div v-if="finances" class="data-list">
        <van-cell-group v-for="(finance, index) in finances" :key="index" :title="finance.store.name">
          <van-cell value-class="cell-value" title="仓库商品总额" :value="`￥${finance.total_amount}`" is-link center @click="toDetailView(finance, 0)" />
          <van-cell value-class="cell-value" title="可售商品总额" :value="`￥${finance.allow_sales_amount}`" is-link center @click="toDetailView(finance, 1)" />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getFinanceReportData } from '@/api/finance-report'
export default {
  components: { myNavBar },
  data() {
    return {
      finances: null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.beginLoad()
      getFinanceReportData(this.query).then(res => {
        this.endLoad()
        this.finances = res.data
      })
    },
    toDetailView(finance, type) {
      this.$router.push({
        path: 'finance-report-detail',
        query: {
          store_id: finance.store.id,
          store_name: finance.store.name,
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .filter {
    position: fixed;
    left: 0;
    top: 46px;
    right: 0;
    z-index: 9;
  }
  .main {
    margin-top: 46px;
    padding: 0 10px 10px;
  }
  #chart {
    height: 300px;
    background-color: #fff;
    padding: 10px 0;
    border-radius: 10px;
  }
  .cell-value {
    color: $amountRedColor;
  }
</style>
